<div
    class="position-absolute w-100 vh-100 m-0 p-0 top-0 overflow-hidden text-white"
    style="z-index: 2011; background-color: var(--dark-background); font-size: 16px !important"
>
    <!-- Intro to page -->
    <div class="position-absolute w-100 h-100 m-0 top-0 loadingBackground" style="z-index: 2100">
        <div class="row h-100 p-0 m-0">
            <div class="col-12 d-flex justify-content-center align-items-center loadingBox">
                <div class="loader mr-5"></div>
                <p class="m-0 p-0">FirePi</p>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div *ngIf="activeAlertCall" class="row p-0 m-0">
        <div class="col-5 m-0 p-0 vh-100 d-flex flex-column">
            <!-- Clock -->
            <div id="clock" class="row box text-center" style="margin-top: 6px">
                <h1 class="m-1 p-0" style="font-weight: 500">
                    {{ this.clock | date: "dd/MM/yyyy HH:mm" }}
                </h1>
            </div>
            <!-- Event -->
            <div id="event" class="row {{ gravitaEvento || 'noGravityCode' }} box">
                <h1 class="m-1 p-0">
                    {{ this.infoSelettiva?.eventoTipologia }}
                </h1>
                <h3 class="m-1 p-0">
                    {{ this.infoSelettiva?.eventoDettaglio }}
                </h3>
            </div>

            <!-- Event details -->
            <div *ngIf="eventEssentialDetails" id="event-details" class="row box">
                <div class="row m-0 p-0">
                    <h5 class="col-xxl-2 p-0">Ora</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">
                        {{ this.getFormattedDate(this.infoSelettiva?.dataOra, true) }}
                        <span class="ms-2">
                            {{ " (" + this.getElapsedTimeFromNow(this.infoSelettiva?.dataOra) + ")" }}
                        </span>
                    </h5>
                    <h5 class="col-xxl-2 p-0">Comune</h5>
                    <h5 class="col-xxl-10 p-0 fw-normal">
                        {{ this.infoSelettiva?.localizzazioneComune }}
                    </h5>
                    <h5 class="col-sm-2 p-0">Frazione</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.localizzazioneFrazione }}</h5>
                    <h5 class="col-xxl-2 p-0">Indirizzo</h5>
                    <h5 class="col-xxl-10 p-0 fw-normal">
                        <p class="m-0 p-0 fw-normal">
                            {{ [this.infoSelettiva?.localizzazioneIndirizzo, this.infoSelettiva?.localizzazioneCivico].join(" ") }}
                        </p>
                        <p class="m-0 p-0 fw-normal">
                            {{ this.infoSelettiva?.localizzazioneAltreInfo }}
                        </p>
                    </h5>
                    <h5 *ngIf="this.infoSelettiva?.eventoNote" class="col-xxl-2 p-0">Note</h5>
                    <h5 *ngIf="this.infoSelettiva?.eventoNote" id="eventNote" class="col-xxl-10 p-0 fw-normal">
                        <p *ngFor="let line of this.infoSelettiva?.eventoNote?.split('\n')" class="m-0 p-0 fw-normal">
                            {{ line }}
                        </p>
                    </h5>
                </div>
            </div>

            <!-- Event response yes counter -->
            <div *ngIf="responderCount" id="event-response-counter" class="row box mx-0 border-0 rounded-0 shadow-none p-0 bg-transparent">
                <div id="event-response-counter-yes" class="text-white col box presentiBackground my-0">
                    <h3 class="m-1 p-0">IN ARRIVO</h3>
                    <h1 class="m-1 p-0">
                        {{ this.partecipanti.length }}
                    </h1>
                </div>
                <div id="event-response-counter-no" class="text-white col box nonPresentiBackground my-0 ml-0">
                    <h3 class="m-1 p-0">NON ARRIVO</h3>
                    <h1 class="m-1 p-0">
                        {{ this.nonPartecipanti.length }}
                    </h1>
                </div>
            </div>

            <!-- Maps -->
            <div class="row box p-0 h-100 overflow-hidden d-flex flex-column box-margin-top box" style="margin-bottom: 6px">
                <!-- Has coordinates or address -->
                @if (
                    (this.infoSelettiva?.localizzazionePosizione?.x && this.infoSelettiva?.localizzazionePosizione?.y) ||
                    (this.infoSelettiva?.localizzazionePosizioneCalcolataDaIndirizzo?.x &&
                        this.infoSelettiva?.localizzazionePosizioneCalcolataDaIndirizzo?.y)
                ) {
                    <app-ol-map
                        class="flex-grow-1 p-0"
                        [center]="[11, 46]"
                        [disableControls]="true"
                        (mapReady)="onMapReady($event)"
                        style="margin: 0"
                    ></app-ol-map>

                    @if (!this.infoSelettiva?.localizzazionePosizione?.x || !this.infoSelettiva?.localizzazionePosizione?.y) {
                        <!-- No coordinates, but address -->
                        <div class="d-flex justify-content-center align-items-center bg-danger p-2 bottom-0">
                            <h5 class="m-0">Posizione calcolata da indirizzo</h5>
                        </div>
                    }
                } @else {
                    <!-- No coordinates and no address -->
                    <div class="w-100 h-100 text-center d-flex justify-content-center align-items-center flex-column">
                        <h3>Posizione non disponibile</h3>
                    </div>
                }
            </div>
        </div>

        <!-- Event details, caller info and notificationsCount -->
        <div *ngIf="eventDetails || notificationsCount" id="event-details" class="col p-0" style="margin: 6px; margin-left: 0px">
            <div *ngIf="eventDetails" class="box m-0">
                <h2 class="w-100 m-0 p-0 text-center">EVENTO</h2>
                <div class="divider"></div>
                <div class="row m-0 p-0">
                    <h5 class="col-sm-2 p-0">Tipo</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.eventoTipologia }}</h5>
                    <h5 class="col-sm-2 p-0">Dettaglio</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.eventoDettaglio }}</h5>
                    <h5 class="col-sm-2 p-0 mb-0">Ora</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">
                        {{ this.getFormattedDate(this.infoSelettiva?.dataOra, true) }}
                        <span class="ms-2">
                            {{ " (" + this.getElapsedTimeFromNow(this.infoSelettiva?.dataOra) + ")" }}
                        </span>
                    </h5>
                    <div class="divider"></div>
                    <h5 class="col-sm-2 p-0">Comune</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.localizzazioneComune }}</h5>
                    <h5 class="col-sm-2 p-0">Frazione</h5>
                    <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.localizzazioneFrazione }}</h5>
                    <h5 class="col-sm-2 p-0 mb-0">Indirizzo</h5>
                    <h5 class="col-sm-10 p-0 fw-normal mb-0">
                        <p class="m-0 p fw-normal">
                            {{ [this.infoSelettiva?.localizzazioneIndirizzo, this.infoSelettiva?.localizzazioneCivico].join(" ") }}
                        </p>
                        <p class="m-0 p fw-normal">
                            {{ this.infoSelettiva?.localizzazioneAltreInfo }}
                        </p>
                    </h5>
                    <div class="divider"></div>
                    <h5 class="col-sm-2 p-0 mb-0">Note</h5>
                    <h5 id="eventNote" class="col-sm-10 p-0 fw-normal mb-0">
                        <p *ngFor="let line of this.infoSelettiva?.eventoNote?.split('\n')" class="m-0 p fw-normal">
                            {{ line }}
                        </p>
                    </h5>
                </div>
            </div>
            <div *ngIf="eventDetails" class="box mx-0 mb-0" style="margin-top: 6px">
                <h2 class="w-100 m-0 p-0 text-center">RICHIEDENTE</h2>
                <div class="divider"></div>
                <div class="row m-0 p-0">
                    <h5 class="col-sm-2 p-0">Nome</h5>
                    <h5 id="callerName" class="col-sm-10 p-0 fw-normal">
                        {{ [this.infoSelettiva?.chiamanteNome, this.infoSelettiva?.chiamanteCognome].join(" ") }}
                    </h5>
                    <h5 class="col-sm-2 p-0 mb-0">Telefono</h5>
                    <h5 id="callerPhone" class="col-sm-10 p-0 fw-normal mb-0">{{ this.infoSelettiva?.chiamanteTelefono }}</h5>
                </div>
            </div>
            <div *ngIf="notificationsCount" class="box mx-0 mb-0" style="margin-top: 6px">
                <h2 class="w-100 m-0 p-0 text-center">UTENTI ALLERTATI</h2>
            </div>
            <div *ngIf="notificationsCount" class="box row mx-0 p-0 border-0 shadow-none bg-transparent" style="margin-top: 2px">
                <div id="event-response-counter-no" class="col box my-0 ml-0" style="margin-right: 2px">
                    <h4 class="m-1 p-0">INVIATE</h4>
                    <h2 class="m-1 p-0">
                        {{ this.infoSelettiva?.notifiche?.inviate || 0 }}
                    </h2>
                </div>
                <div id="event-response-counter-no" class="col box my-0 ml-0" style="margin-right: 2px">
                    <h4 class="m-1 p-0">RICEVUTE</h4>
                    <h2 class="m-1 p-0">
                        {{ this.infoSelettiva?.notifiche?.ricevute || 0 }}
                    </h2>
                </div>
                <div id="event-response-counter-no" class="col box m-0">
                    <h4 class="m-1 p-0">LETTE</h4>
                    <h2 class="m-1 p-0">
                        {{ this.infoSelettiva?.notifiche?.lette || 0 }}
                    </h2>
                </div>
            </div>
        </div>

        <!-- Participants -->
        <div *ngIf="presentiList" id="presentiList" class="col p-0 text-white mx-0" style="margin: 6px">
            <div id="presentiListTitle" class="boxUtenti presentiBackground">
                <h2 class="w-100 m-0 p-0 text-center">IN ARRIVO - {{ this.partecipanti.length }}</h2>
            </div>
            <div id="presentiListItem" *ngFor="let partecipante of partecipanti; let i = index" class="boxUtenti presentiBackground">
                <h3 class="m-0 p-0">{{ partecipante.nome }} {{ partecipante.cognome }}</h3>
                <h5 class="m-0 p-0 fw-normal">{{ getNameByType(partecipante.tipoPartecipante) }}</h5>
            </div>
        </div>
        <div *ngIf="nonPresentiList" id="nonPresentiListn" class="col p-0 text-white ms-0" style="margin: 6px">
            <div id="nonPresentiListTitle" class="boxUtenti nonPresentiBackground">
                <h2 class="w-100 m-0 p-0 text-center">NON ARRIVO - {{ this.nonPartecipanti.length }}</h2>
            </div>
            <div id="nonPresentiListItem" *ngFor="let partecipante of nonPartecipanti" class="boxUtenti nonPresentiBackground">
                <h3 class="m-0 p-0">{{ partecipante.nome }} {{ partecipante.cognome }}</h3>
                <h5 class="m-0 p-0 fw-normal">{{ getNameByType(partecipante.tipoPartecipante) }}</h5>
            </div>
        </div>
    </div>

    <!-- Waiting for alert call -->
    <div *ngIf="!activeAlertCall" class="w-100 h-100 d-flex flex-column bg-dark">
        <div class="w-100 bg-danger p-3 d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <i class="h1 bi bi-fire mb-0 me-3"></i>
                <h1 class="text-center mb-0">
                    VVF {{ !lastAlertCalls[0] || !lastAlertCalls[0].corpo ? "Unknown" : lastAlertCalls[0].corpo.CRP_DESCRIZIONE }}
                </h1>
            </div>
            <h1 class="text-center mb-0">FirePi</h1>
        </div>

        <div class="h-100 row g-0">
            <div class="col">
                <div class="h-100 p-3">
                    <div class="d-flex flex-column h-100 justify-content-center align-items-center">
                        <h1 class="standby-time">{{ this.clock | date: "HH:mm" }}</h1>
                        <h1 class="standby-date">{{ this.clock | date: "dd MMM yyyy" }}</h1>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="h-100 p-3">
                    <div class="d-flex flex-column h-100 justify-content-center align-items-center col-11">
                        <p class="mb-4 standby-alert-title fw-bold">Ultime allerte:</p>
                        <div *ngFor="let alert of lastAlertCalls" class="d-flex mb-2 align-items-center justify-content-between w-100">
                            <div class="d-flex align-items-center">
                                <i class="{{ monitorService.getIconForAlertcall(alert.eventoTipologia) }} standby-alert-icon me-4"></i>
                                <p class="standby-alert-text mb-0">{{ alert.dataOra | date: "dd/MM/yyyy HH:mm" }}</p>
                            </div>
                            <p class="standby-alert-text ms-5 mb-0">{{ alert.eventoTipologia }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Error popup -->
    <div *ngIf="errorBox" class="position-absolute w-100 h-100 m-0 top-0 errorBackground" style="z-index: 2050">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center">
            <div class="bg-danger w-100 p-5 text-center">
                <h1 class="m-0 p-0 mb-2">{{ this.errorTitle }}</h1>
                <h4 class="m-0 p-0">{{ this.errorDescription }}</h4>
            </div>
        </div>
    </div>
</div>
