<div class="position-absolute w-100 vh-100 m-0 p-0 top-0 background overflow-hidden" style="z-index: 2011">
    <div id="header" class="header text-white p-2 d-flex flex-row align-items-center justify-content-center w-100">
        <h1 class="m-0">FirePi:<span class="fw-normal"> Selettive VVF Trentino</span></h1>
        <h2 class="fw-normal my-0 ml-auto">{{ this.clock }}</h2>
    </div>

    <div class="row m-0 mt-1 p-0">
        <table class="table big-fonts m-0 p-0 w-100 h-100">
            <thead class="table-borderless table-primary">
                <tr>
                    <th class="cell cell-header cell-corpo">Ora/Corpo</th>
                    <th colspan="2" class="cell cell-header">Evento</th>
                    <th class="cell cell-header">Luogo</th>
                    <th class="cell cell-header cell-number text-center">Allerte Inviate</th>
                    <th *ngIf="viewAnswers" class="cell cell-number text-center">Risposte</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let selettiva of selettivaList" id="row-table" class="dark-box hideIfOverflow">
                    <td class="cell cell-corpo {{ isNewAlertCall(selettiva) ? 'new-call' : '' }}">
                        <span class="fw-bold">{{ selettiva.corpoDescrizione.toUpperCase() }}</span>
                        <span class="d-flex align-items-center">
                            {{ selettiva.dataOra | date: " HH:mm dd/MM/yy" }}
                            <span *ngIf="eventInProgress(selettiva)" class="px-1 ml-2 fw-normal bg-danger rounded-2 newCall">Nuova</span>
                        </span>
                    </td>
                    <td class="cell cell-gravity fw-bold text-center {{ selettiva.gravitaEvento || 'noGravityCode' }}"></td>
                    <td class="cell {{ isNewAlertCall(selettiva) ? 'new-call' : '' }}">
                        <span class="fw-bold d-flex align-items-center">
                            {{ selettiva.eventoTipologia }}
                        </span>
                        {{ selettiva.eventoDettaglio }}
                    </td>
                    <td class="cell {{ isNewAlertCall(selettiva) ? 'new-call' : '' }}">
                        <span class="fw-bold">{{ selettiva.localizzazioneComune }}</span>
                        <ng-container *ngIf="selettiva.localizzazioneComune && selettiva.localizzazioneIndirizzo"> <br /></ng-container>
                        {{ selettiva.localizzazioneIndirizzo }}
                    </td>
                    <td class="cell cell-number text-center {{ isNewAlertCall(selettiva) ? 'new-call' : '' }}">
                        <div class="row row-cols-2 m-0 p-0">
                            <span class="col p-0 fw-bolder text-end">App</span>
                            <span class="col p-0 ps-2 text-start">{{ selettiva.vigiliAllertati }}</span>
                            <span class="col p-0 fw-bolder text-end">Tetra</span>
                            <span class="col p-0 ps-2 text-start">N/A</span>
                        </div>
                    </td>
                    <td *ngIf="viewAnswers" class="cell d-flex flex-column text-center {{ isNewAlertCall(selettiva) ? 'new-call' : '' }}">
                        <div class="mx-auto">
                            <div class="fw-bold bg-success rounded-top px-3">
                                <span class="pi pi-check fw-bolder mr-3"></span>
                                <span class="">{{ selettiva.inArrivo }}</span>
                            </div>
                            <div class="fw-bold bg-danger rounded-bottom px-3">
                                <span class="pi pi-times fw-bolder mr-3"></span>
                                <span class="">{{ selettiva.nonInArrivo }}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
