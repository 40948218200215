import { Component, OnDestroy, OnInit } from '@angular/core';
import { THEME_ICONS } from '@shared/faIcons';
import { SelettivaService } from '@core/http-gen/services/selettiva.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { SelettivaVolontarioDto } from '@core/http-gen/models/selettiva-volontario-dto';
import { SelettivaDto } from '@core/http-gen/models/selettiva-dto';
import { DispositivoMobileService } from '@core/http-gen/services/dispositivo-mobile.service';
import { environment } from '@env';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { NgxAuthService } from 'ngx-auth-utils';
import { map, switchMap, tap } from 'rxjs/operators';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_MONTH, ONE_SECOND } from '@shared/utils/time.utils';
import { v4 as uuidv4 } from 'uuid';
import { SelettivaListItemDto } from '@core/http-gen/models';
import { SelettivaData } from './display-selettive-full.models';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { Permesso, PermessoValore } from '@shared/types/auth.models';
import { getValueOrError } from '@core/errors/exception';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';

// Services
import { MonitorService } from '@core/services/monitor.service';

// Models
import { AlertCall } from '@core/models/entry.model';

@UntilDestroy()
@Component({
    selector: 'app-display-selettive-full',
    templateUrl: './display-selettive-full.component.html',
    styleUrls: ['./display-selettive-full.component.scss'],
})
/* eslint-disable no-console */
export class displaySelettiveFullComponent implements OnInit, OnDestroy {
    readonly icons = THEME_ICONS;
    private mouseMoveTimeout: number | undefined;

    public partecipantiCounter = 0;
    public nonPartecipantiCounter = 0;
    public partecipanti: Array<SelettivaVolontarioDto> = [];
    public nonPartecipanti: Array<SelettivaVolontarioDto> = [];
    public infoSelettiva?: SelettivaDto;
    public gravitaEvento?: string | undefined;

    public selectedSelettiva: SelettivaListItemDto | null = null;

    public canViewStorico = false;
    public canViewVigiliInArrivo = false;

    private sub?: Subscription;
    private monitorDevInterval$ = new BehaviorSubject<number>(1500);

    private intervalClockId: number | undefined;
    public clock = new Date();

    public errorBox = false;
    public errorTitle = 'Ops, qualcosa è andato storto';
    public errorDescription = 'Qualcosa è andato storto, riprova più tardi';

    public activeAlertCall = false;

    public lastAlertCalls: AlertCall[] = [];

    // Debug
    public debugStatus: 'online' | 'offline' = 'online';
    public debugLastUpdate = new Date();
    public debugNextRefreshPage = new Date();

    map!: Map;

    constructor(
        private selettivaService: SelettivaService,
        private dispositivoService: DispositivoMobileService,
        private authService: NgxAuthService,
        private _permissionChecker: PermissionCheckerService,
        protected monitorService: MonitorService,
    ) {}

    ngOnInit(): void {
        this.canViewStorico = this._permissionChecker.hasThePermission(
            Permesso.StoricoSelettive,
            PermessoValore.Lettura,
            this.authService.snapshot.user.tokenData.permessoList,
        );

        this.canViewVigiliInArrivo = this._permissionChecker.hasThePermission(
            Permesso.VigiliInArrivo,
            PermessoValore.Lettura,
            this.authService.snapshot.user.tokenData.permessoList,
        );

        if (!this.canViewVigiliInArrivo || !this.canViewStorico) {
            this.showError('Errore di permessi', 'Settare i permessi: StoricoSelettive e VigiliInArrivo');
            return;
        } else {
            this.hideError();
        }

        // Remove the previous event listener
        navigator.serviceWorker.removeEventListener('message', () => this.onBackgroundMessage());

        // Add event listener for background messages
        navigator.serviceWorker.addEventListener('message', () => this.onBackgroundMessage());

        this._startCurrentDateTime();

        this._updateData((infoSelettiva) => this.startPolling(new Date(infoSelettiva.dataOra)));

        this.requestPermission();

        this.monitorService.scheduleRefresh();

        this.getLastAlertCalls();

        setTimeout(() => {
            this.refreshMap();
            this.startEvents();
            this.listenForNotifications();
        }, 500);
    }

    private showError(title: string, description: string): void {
        this.errorBox = true;
        this.errorTitle = title;
        this.errorDescription = description;
    }

    private hideError(): void {
        this.errorBox = false;
        this.errorTitle = '';
        this.errorDescription = '';
    }

    private startEvents(): void {
        // Hide mouse cursor after 5 seconds of inactivity
        document.body.addEventListener('mousemove', () => {
            document.body.style.cursor = 'default';
            clearTimeout(this.mouseMoveTimeout);
            this.mouseMoveTimeout = window.setTimeout(() => {
                document.body.style.cursor = 'none';
            }, 3000);
        });

        // Add event listener for resize page
        window.addEventListener('resize', () => {
            this.refreshMap();
        });

        // Check if is online or offline
        window.addEventListener('offline', () => {
            this.debugStatus = 'offline';
            this.showError('Errore di connessione', 'Non sei connesso ad internet, controlla la tua connessione');
        });
        window.addEventListener('online', () => {
            this.debugStatus = 'online';
            this.hideError();
            window.location.reload();
        });

        // Launch check for night standby mode
        // this.nightStandby();
    }

    private hideSensitiveData(): void {
        const listComponents = ['eventNote', 'callerName', 'callerPhone'];

        for (const component of listComponents) {
            const element = document.getElementById(component);
            if (element) {
                element.classList.add('hidden-text');
            }
        }
    }

    private showSensitiveData(): void {
        const listComponents = ['eventNote', 'callerName', 'callerPhone'];

        for (const component of listComponents) {
            const element = document.getElementById(component);
            if (element) {
                element.classList.remove('hidden-text');
            }
        }
    }

    public onMapReady(map: Map): void {
        this.map = map;
        this.setView();
    }

    private setView(): void {
        if (!this.map) return;
        if (!this.infoSelettiva) return;

        const coordinate = this.monitorService.getCoordinateFromAlertcall(this.infoSelettiva);
        if (!coordinate) return;

        this.refreshMap();
        this.map.setView(
            new View({
                center: fromLonLat([coordinate.lon, coordinate.lat]),
                zoom: 16,
            }),
        );
        this.addMarker();
    }

    private addMarker(): void {
        if (!this.map) return;
        if (!this.infoSelettiva) return;

        // Remove previous markers
        this.map.getLayers().forEach((layer) => {
            if (layer instanceof VectorLayer) {
                this.map.removeLayer(layer);
            }
        });

        const coordinate = this.monitorService.getCoordinateFromAlertcall(this.infoSelettiva);
        if (!coordinate) return;

        const marker = new Feature({
            geometry: new Point(fromLonLat([coordinate.lon, coordinate.lat])),
        });

        const markerStyle = new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: '/assets/icons/marker.png',
            }),
        });

        marker.setStyle(markerStyle);

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [marker],
            }),
        });

        this.map.addLayer(vectorLayer);
    }

    private refreshMap(): void {
        if (this.map) {
            this.map.updateSize();
        }
    }

    private _startCurrentDateTime(): void {
        let lastMinute = new Date().getMinutes();

        this.intervalClockId = window.setInterval(() => {
            const now = new Date();

            if (lastMinute == now.getMinutes()) {
                return;
            }

            lastMinute = now.getMinutes();
            // this.clock = this.getFormattedDate(now, false);
            this.clock = now;
        }, 1000);
    }

    public getFormattedDate(date: Date | string | undefined, seconds = false): string {
        if (!date) return '';

        if (typeof date === 'string') {
            date = new Date(date);
        }

        let strTime = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');

        if (seconds) {
            strTime += ':' + date.getSeconds().toString().padStart(2, '0');
        }

        const strDate =
            date.getDate().toString().padStart(2, '0') +
            '/' +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear().toString().padStart(2, '0');

        return strDate + ' ' + strTime;
    }

    public getElapsedTimeFromNow(date: Date | string | undefined): string {
        if (!date) return '';

        if (typeof date === 'string') {
            date = new Date(date);
        }

        const now = new Date();
        const diff = now.getTime() - date.getTime();

        // Less than 1 minute
        if (diff < ONE_MINUTE) {
            return 'adesso';
        }

        // Less than 1 hour
        if (diff < ONE_MINUTE * 60) {
            const minutes = Math.floor(diff / ONE_MINUTE);
            if (minutes === 1) return '1 minuto fa';
            else return minutes + ' minuti fa';
        }

        // less than 1 day
        if (diff < ONE_HOUR * 24) {
            const hours = Math.floor(diff / ONE_HOUR);
            if (hours === 1) return '1 ora fa';
            else return hours + ' ore fa';
        }

        // Less than 1 month, 30 days (i know it's not accurate)
        if (diff < ONE_DAY * 30) {
            const days = Math.floor(diff / ONE_DAY);
            if (days === 1) return '1 giorno fa';
            else return days + ' giorni fa';
        }

        // Less than 1 year, 365 days (i know it's not accurate)
        if (diff < ONE_DAY * 365) {
            const months = Math.floor(diff / ONE_MONTH);
            if (months === 1) return '1 mese fa';
            else return months + ' mesi fa';
        }

        // More than 1 year, is impossible to reach this point, but just in case
        const years = Math.floor(diff / ONE_DAY / 365);
        if (years === 1) return '1 anno fa?!';
        else return years + ' anni fa?!';
    }

    private onBackgroundMessage(): void {
        console.log('Background message received');
        this.monitorDevInterval$ = new BehaviorSubject<number>(3000);
        this.startPolling(new Date());
    }

    private startPolling(pollingStartDate: Date): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        this.sub = this.monitorDevInterval$
            .pipe(
                switchMap((value) => interval(value)),
                tap(() => {
                    this.checkTimerIncrement(pollingStartDate);
                    this._updateData(() => ({}));
                }),
            )
            .subscribe();
    }

    /**
     * Per i primi 5 minuti (nei quali ci aspettiamo di ricevere la maggior parte delle risposte) il polling è ogni 10 secondi
     * Dai 5 ai 15 minuti ogni 30 secondi
     * Dopo i 15 minuti si stoppa il polling e si nascondono i dati sensibili
     * Dopo i 30 minuti fine della chiamata, si abilita la modalità standby
     */
    private checkTimerIncrement(startDate: Date): void {
        const elapsed = new Date().getTime() - startDate.getTime();

        if (elapsed > ONE_MINUTE * 30) {
            this.hideSensitiveData();
            this.getLastAlertCalls();
            this.activeAlertCall = false;

            this.sub?.unsubscribe();
            return;
        } else if (elapsed > ONE_MINUTE * 15) {
            this.hideSensitiveData();
            this.activeAlertCall = true;
            this.monitorDevInterval$.next(ONE_MINUTE * 2);
            return;
        } else if (elapsed > ONE_MINUTE * 5) {
            this.showSensitiveData();
            this.activeAlertCall = true;

            this.monitorDevInterval$.next(ONE_SECOND * 30);
        } else {
            this.showSensitiveData();
            this.activeAlertCall = true;

            this.monitorDevInterval$.next(ONE_SECOND * 10);
            return;
        }
    }

    private requestPermission(): void {
        const messaging = getMessaging();

        getToken(messaging, { vapidKey: environment.firebase.vapidKey })
            .then((currentToken) => {
                if (currentToken) {
                    const oldToken = localStorage.getItem('tokenDispositivoMobile');
                    let deviceUid = localStorage.getItem('deviceUid');
                    if (!deviceUid) {
                        deviceUid = uuidv4();
                        localStorage.setItem('deviceUid', deviceUid);
                    }

                    if (oldToken !== currentToken) {
                        localStorage.setItem('tokenDispositivoMobile', currentToken);
                        this.dispositivoService
                            .apiDispositivoMobileInsertOrUpdatePost({
                                body: {
                                    deviceUId: deviceUid,
                                    newToken: currentToken,
                                    tipoDispositivo: 3,
                                    tipoUpdate: 3,
                                },
                            })
                            .subscribe();
                    }
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    this.showError('Errore di permessi', 'Autorizzazione non riuscita per le notifiche');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                this.showError('Errore di permessi', 'Errore durante la richiesta di permessi per le notifiche');
            });
    }

    private listenForNotifications(): void {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            if (payload.data?.tipoPushNotification === '99' || payload.data?.tipoPushNotification === '98') {
                this.monitorDevInterval$ = new BehaviorSubject<number>(3000);
                this.startPolling(new Date());
            }
        });
    }

    /**
     * Legge l'id dell'ultima selettiva visibile dall'utente, se è cambiato, ricarica la lista e la nuova selettiva
     * altrimenti carica solamente i dati della selettiva selezionata
     */
    public _updateData(extraActions?: (infoSelettiva: SelettivaDto) => void): void {
        //TODO gestire più selettive?
        this.selettivaService
            .apiSelettivaSelettivaLastIdGet()
            .pipe(
                untilDestroyed(this),
                switchMap((selettivaId: number) => {
                    this.debugLastUpdate = new Date();

                    // Aggiorno i dettagli della selettiva
                    return this._refreshSelettivaData(selettivaId, extraActions);
                }),
            )
            .subscribe();
    }

    /** Order the partecipanti and nonPartecipanti lists, put 'autista' first in the list */
    private orderAllLists(): void {
        this.partecipanti = this.partecipanti.sort((a) => {
            if (a.tipoPartecipante === 2) {
                return -1;
            } else {
                return 1;
            }
        });

        this.nonPartecipanti = this.nonPartecipanti.sort((a) => {
            if (a.tipoPartecipante === 2) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    private answerDivHeight(): number {
        const answerTitle = document.getElementById('answerTitle');
        const div = document.getElementById('answer');
        if (div && answerTitle) {
            return Math.floor(div.clientHeight - answerTitle.clientHeight);
        }
        return 1000;
    }

    private itemAnswerHeight(): number {
        let itemHeight = 100; // Default value
        if (document.getElementById('presentiListItem') && document.getElementById('presentiListItem')?.clientHeight !== 0) {
            itemHeight = document.getElementById('presentiListItem')?.clientHeight || 0;
        }
        if (document.getElementById('nonPresentiListItem') && document.getElementById('nonPresentiListItem')?.clientHeight !== 0) {
            itemHeight = document.getElementById('nonPresentiListItem')?.clientHeight || 0;
        }
        return itemHeight;
    }

    private limitListPresenti(): void {
        const spaceAvailable = this.answerDivHeight();
        const itemHeight = this.itemAnswerHeight();

        const count = Math.floor(spaceAvailable / itemHeight);

        if (this.partecipanti.length == 0) {
            return;
        }

        const presentiCount = this.partecipanti.length;

        if (presentiCount > count) {
            this.partecipanti = this.partecipanti.slice(0, count - 1);

            const remaining = presentiCount - count + 1;
            this.partecipanti.push({
                cognome: '+' + remaining + ' vigili',
                nome: '',
                isPartecipante: true,
                tipoPartecipante: -1,
                nomeUtente: '',
                utenteId: 0,
                dataOra: '',
            });
        }
    }

    private limitListNonPresenti(): void {
        const spaceAvailable = this.answerDivHeight();
        const itemHeight = this.itemAnswerHeight();

        const count = Math.floor(spaceAvailable / itemHeight);

        if (this.nonPartecipanti.length == 0) {
            return;
        }

        const presentiCount = this.nonPartecipanti.length;

        if (presentiCount > count) {
            this.nonPartecipanti = this.nonPartecipanti.slice(0, count - 1);

            const remaining = presentiCount - count + 1;
            this.nonPartecipanti.push({
                cognome: '+' + remaining + ' vigili',
                nome: '',
                isPartecipante: true,
                tipoPartecipante: -1,
                nomeUtente: '',
                utenteId: 0,
                dataOra: '',
            });
        }
    }

    /** Esegue le chiamate per aggiornare i dati della selettiva, e li imposta negli oggetti visibili nella pagina */
    private _refreshSelettivaData(
        selettivaId: number,
        extraActions?: (infoSelettiva: SelettivaDto) => void,
    ): Observable<SelettivaData | null> {
        if (!isNaN(selettivaId) || selettivaId != 0) {
            return forkJoin({
                partecipanti: this.canViewVigiliInArrivo
                    ? this.selettivaService.apiSelettivaSelettivaVolontarioListGet({
                          selettivaId,
                      })
                    : of([]),

                infoSelettiva:
                    this.infoSelettiva == null || selettivaId != this.infoSelettiva.id
                        ? this.selettivaService.apiSelettivaSelettivaByIdGet({ selettivaId })
                        : of(getValueOrError(this.infoSelettiva)),
            }).pipe(
                map((selettivaData: SelettivaData) => {
                    if (selettivaData != null) {
                        this.infoSelettiva = selettivaData.infoSelettiva;
                        if (this.canViewVigiliInArrivo) {
                            this.partecipanti = selettivaData.partecipanti.filter((p) => p.isPartecipante);
                            this.nonPartecipanti = selettivaData.partecipanti.filter((p) => !p.isPartecipante);
                        } else {
                            this.partecipanti = [];
                            this.nonPartecipanti = [];
                        }

                        if (extraActions) {
                            extraActions(this.infoSelettiva);
                        }

                        this.orderAllLists();

                        // Save the number of items in the list, before limiting it
                        this.partecipantiCounter = this.partecipanti.length;
                        this.nonPartecipantiCounter = this.nonPartecipanti.length;

                        // Limit the number of items in the list
                        this.limitListPresenti();
                        this.limitListNonPresenti();

                        this.gravitaEvento = this.getGravita(selettivaData.infoSelettiva.eventoCodice);
                    } else {
                        this.infoSelettiva = undefined;
                        this.partecipanti = [];
                        this.nonPartecipanti = [];
                    }

                    // Set map view
                    this.setView();
                    return selettivaData;
                }),
            );
        } else {
            return of(null);
        }
    }

    public getNameByType(type: number): string {
        switch (type) {
            case 1:
                return 'Vigile';
            case 2:
                return 'Autista';
        }
        return '';
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
        this.sub = undefined;

        if (this.intervalClockId) {
            clearInterval(this.intervalClockId);
        }

        this.monitorService.clearRefresh();
    }

    public getGravita(codice?: string): string {
        if (!codice) {
            return 'noGravityCode';
        }
        const regex = new RegExp('[a-zA-Z]');
        const result = regex.exec(codice);

        return result ? result[0] : 'a';
    }

    private async getLastAlertCalls(): Promise<void> {
        this.lastAlertCalls = await this.monitorService.getLastAlertcalls();
    }
}
