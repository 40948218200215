/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SelettivaDto } from '../../models/selettiva-dto';

export interface ApiSelettivaSelettivaByIdGet$Params {
    /**
     * id della selettiva da caricare
     */
    selettivaId: number;
}

export function apiSelettivaSelettivaByIdGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiSelettivaSelettivaByIdGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<SelettivaDto>> {
    const rb = new RequestBuilder(rootUrl, apiSelettivaSelettivaByIdGet.PATH, 'get');
    if (params) {
        rb.query('selettivaId', params.selettivaId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<SelettivaDto>;
        }),
    );
}

apiSelettivaSelettivaByIdGet.PATH = '/api/selettiva/selettiva-by-id';
